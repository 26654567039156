/* eslint-disable no-param-reassign */
import { API } from 'aws-amplify';
import * as mutations from '@/graphql/mutations';
import * as queries from '@/graphql/queries';
export default {
  async getDetail(id) {
    return API.graphql({
      query: queries.getArticleComment,
      variables: { id },
      authMode: 'AMAZON_COGNITO_USER_POOLS',
    });
  },
  async getList(variables = {}) {
    return API.graphql({
      query: queries.listArticleCommentsOrderByCreatedAt,
      authMode: 'AMAZON_COGNITO_USER_POOLS',
      variables,
    })
  }, 
  async getListReply() {
    return API.graphql({
      query: queries.listArticleReplyComments,
      authMode: 'AMAZON_COGNITO_USER_POOLS',
      variables: {
        sortDirection: 'DESC',
        statusCode: 'ACTIVE', 
      },
    })
  }, 
  
  async updateComment(input) { 
    delete input.article
    delete input.replyComments
    delete input.createdAt
    delete input.updatedAt
    delete input.owner
    return API.graphql({
      query: mutations.updateArticleComment,
      variables: { input },
      authMode: 'AMAZON_COGNITO_USER_POOLS',
    })
  },
  async createUpdateReply(input) {
    if (!input.id) {
      return API.graphql({
        query: mutations.createArticleReplyComment,
        variables: { input },
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      })
    } 
    delete input.articleComment
    delete input.replyComments
    delete input.createdAt
    delete input.updatedAt
    delete input.owner
    return API.graphql({
      query: mutations.updateArticleReplyComment,
      variables: { input },
      authMode: 'AMAZON_COGNITO_USER_POOLS',
    })
  },
  async delete(id) {
    return API.graphql({
      query: mutations.deleteArticleComment,
      variables: { input: { id } },
      authMode: 'AMAZON_COGNITO_USER_POOLS',
    });
  },
  async deleteReply(id) {
    return API.graphql({
      query: mutations.deleteArticleReplyComment,
      variables: { input: { id } },
      authMode: 'AMAZON_COGNITO_USER_POOLS',
    });
  },

}
