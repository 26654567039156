<template>
  <div>
    <b-overlay
      id="overlay-background"
      :show="isLoadingDetails"
      :variant="`transparent`"
      :opacity="0.5"
      :blur="`10px`"
      rounded="sm"
      spinner-type="grow"
    >
      <b-card>
        <b-card-body>
          <b-media
            v-if="details && details.article"
            class="mb-3"
            vertical-align="top"
          >
            <template #aside>
              <b-img
                :src="details.article.image"
                width="64"
              />
            </template>
            <h4 class="media-heading">
             <b-link
                :to="{ name: 'add-edit-article', query: { id: details.article.id } }"
              >{{ details.article.title }}</b-link>
             
            </h4>
            <b-card-text class="mb-0">
              {{ details.article.description }}
            </b-card-text>
          </b-media>

          <b-media v-if="details">
            <template #aside>
              <b-avatar size="lg" />
            </template>
          
            <validation-observer
              #default="{ handleSubmit }"
              ref="refFormObserver"
            >
              <b-form
                @submit.prevent="handleSubmit(onUpdate)"
                @reset.prevent="resetForm"
              >
                <div class="row">
                  <div class="col-12">
                    <h5 class="mt-0">
                      <validation-provider
                        #default="validationContext"
                        name="comment"
                        rules="required"
                      >
                        <b-form-group>
                          <b-form-input
                            id="baliseTitle"
                            v-model="details.comment"
                            trim
                            :placeholder="$t('common.form.please_insert_data')"
                          /> 
                          <b-form-invalid-feedback>
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </h5>
                    <b-card-text>
                      <validation-provider
                        #default="validationContext"
                        name="comment"
                        rules="required"
                      >
                        <b-form-group>
                          <b-form-textarea
                            id="title"
                            v-model="details.comment"
                            :state="details.comment ? true : false"
                            trim
                            :placeholder="$t('common.form.please_insert_data')"
                          />

                          <b-form-invalid-feedback>
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-card-text>
                  </div>
                  <div class="col-12">
                    <b-row align-h="end">
                      <b-form-checkbox
                        v-model="details.isPublic"
                        :value="true"
                        :unchecked-value="false"
                        class="custom-control-primary mb-75"
                        name="check-button"
                        switch
                        inline
                      >{{ $t("common.form.publish") }}
                      </b-form-checkbox>
                    </b-row>
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="primary"
                      block
                      type="submit"
                    >
                      {{ $t("common.form.save") }}
                    </b-button>
                  </div>
                </div>
              </b-form>
            </validation-observer> 
          
            <template v-if="details.replyComments">
             
              <validation-observer
                v-for="(item, index) in details.replyComments.items"
                #default="{ handleSubmit }"
                ref="refFormObserver"
                :key="index"
              >
                <b-form
                  @submit.prevent="handleSubmit(onUpdateReply(item))"
                  @reset.prevent="resetForm"
                >
                  <div
                    class="row"
                  >
                    <div class="col-12">
                      <b-media
           
                        class="mt-3"
                      >
                        <template #aside>
                          <b-avatar
                            size="lg"
                            variant="primary"
                          />
                        </template>

                        <h5 class="mt-0">
                          Lesfrenchies
                        </h5>
                        <b-card-text class="mb-0">
                          <validation-provider
                            #default="validationContext"
                            name="comment"
                            rules="required"
                          >
                            <b-form-group>
                              <b-form-textarea
                                id="title"
                                v-model="item.comment"
                                :state="item.comment ? true : false"
                                trim
                                :placeholder="$t('common.form.please_insert_data')"
                              />

                              <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                              </b-form-invalid-feedback>
                            </b-form-group>
                            <div>
                              <b-form-checkbox
                                v-model="item.isPublic"
                                :value="true"
                                :unchecked-value="false"
                                class="custom-control-primary mb-75"
                                name="check-button"
                                switch
                                inline
                              >{{ $t("common.form.publish") }}
                              </b-form-checkbox>
                            </div>
                          </validation-provider>
                        </b-card-text>
                        <div class="group-buttons mt-1">
                          
                          <b-button
                            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                            variant="primary"
                            type="submit"
                            class="mr-1"
                            size="sm"
                          >
                            {{ $t("common.form.save") }}
                          </b-button>
                          <b-button
                            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                            v-b-modal.modal-prevent-closing
                            variant="danger"
                            type="button"
                            size="sm"
                            @click="onSelectedDelete(item)"
                          >
                            {{ $t("common.form.delete") }}
                          </b-button>
                        </div>
                      </b-media>
                    </div>
                  </div>
                </b-form>
              </validation-observer>
                  
            </template>
           
          </b-media>
        </b-card-body>
      </b-card>
    </b-overlay>
    <b-overlay
      id="overlay-background"
      :show="isLoading"
      :variant="`transparent`"
      :opacity="0.5"
      :blur="`10px`"
      rounded="sm"
      spinner-type="grow"
    >
      <section>
        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >
          <b-form
            @submit.prevent="handleSubmit(onSubmitReply)"
            @reset.prevent="resetForm"
          >
            <b-card no-body>
              <b-card-body>
                <div class="row">
                  <div class="col-12">
                    <b-row align-h="end">
                      <b-form-checkbox
                        v-model="inputData.isPublic"
                        :value="true"
                        :unchecked-value="false"
                        class="custom-control-primary mb-75"
                        name="check-button"
                        switch
                        inline
                      >{{ $t("common.form.publish") }}
                      </b-form-checkbox>
                    </b-row>
                  </div>
                  <div class="col-12">
                    <validation-provider
                      #default="validationContext"
                      name="comment"
                      rules="required"
                    >
                      <b-form-group
                        :label="$t('common.general.comment')"
                        label-for="title"
                      >
                        <b-form-textarea
                          id="title"
                          v-model="inputData.comment"
                          :state="inputData.comment ? true : false"
                          trim
                          :placeholder="$t('common.form.please_insert_data')"
                        />

                        <b-form-invalid-feedback>
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </div>
                  <div class="col-12">
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="primary"
                      block
                      type="submit"
                    >
                      {{ $t("common.form.save") }}
                    </b-button>
                  </div>
                </div></b-card-body>
            </b-card>
          </b-form>
        </validation-observer>
      </section>
    </b-overlay>
    <b-modal
      id="modal-prevent-closing"
      ref="my-modal"
      :title="$t('common.form.title_confirm_delete')"
      :ok-title="$t('common.form.delete')"
      :cancel-title="$t('common.form.cancel')"
      cancel-variant="outline-secondary"
      @ok="handleOk"
    >
      <b-card-text>
        {{ $t("common.form.des_confirm_delete") }}
      </b-card-text>
    </b-modal>
  </div>
</template>
<script>
import {
  BRow,
  BCard,
  BCardBody,
  BForm,
  BFormGroup,
  BFormTextarea,
  VBToggle,
  BFormInvalidFeedback,
  BFormCheckbox,
  BOverlay,
  BMedia,
  BAvatar,
  BCardText,
  BButton,
  BFormInput,
  BModal,
  VBModal,
  BImg,
  BLink,

} from 'bootstrap-vue';
import { required } from '@validations';
import Ripple from 'vue-ripple-directive';
import formValidation from '@core/comp-functions/forms/form-validation';
import commentServices from '@/services/commentServices';
import commonServices from '@/services/commonServices';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import _ from 'lodash';
export default {
  components: {
    BAvatar,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCard,
    BCardBody,
    BForm,
    BFormGroup,
    BFormTextarea,
    BFormInvalidFeedback,
    BFormCheckbox,
    BOverlay,
    BMedia,
    BCardText,
    BButton,
    BFormInput,
    BModal,
    BImg,
    BLink,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    'b-modal': VBModal,
  },

  data() {
    return {
      isLoading: false,
      isLoadingDetails: false,
      id: this.$route.query.id ? this.$route.query.id : null,
      details: {},
      itemSelectedDelete: {},
      inputData: {
        comment: '',
        isPublic: true,
        statusCode: 'ACTIVE',
        articleCommentID: this.$route.query.id ? this.$route.query.id : null,
      },
      required,
    };
  },
  computed: {
    uploadDisabled() {
      return this.files.length === 0;
    },
  },
  watch: {
    data(value) {
      this.data = value;
      this.inputData = JSON.parse(JSON.stringify(this.data));
      return this.data;
    },
  },
  async mounted() {
    if (this.id) {
      await this.getDetails();
    }
  },
  setup() {
    const { refFormObserver, getValidationState, resetForm } = formValidation(
      {},
    );
    return {
      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
  methods: {
    async onUpdate() {
      this.isLoadingDetails = true;  
      const variables = _.cloneDeep(this.details);
      await commentServices
        .updateComment(variables)
        .then((result) => {
          if (result) {
            if (result.errors) {
              commonServices.showError(this, 'msg.error.error');
            } else {
              commonServices.showSuccess(this, 'msg.success.success');
            }
          }
        })
        .catch(() => {
          commonServices.showError(this, 'msg.error.error');
        })
        .finally(() => {
          this.isLoadingDetails = false;
        });
    },
    onSubmitReply(){
      this.onCreateUpdateReply(this.inputData);
    },
    onUpdateReply(item){
      this.onCreateUpdateReply(item);
    },
    async onCreateUpdateReply(variables) {
      this.isLoading = true;  
      await commentServices
        .createUpdateReply(variables)
        .then((result) => {
          if (result) {
            if (result.errors) {
              commonServices.showError(this, 'msg.error.error');
            } else {
              commonServices.showSuccess(this, 'msg.success.success');
              this.getDetails();
              if (!variables.id){
                this.inputData.comment = '';
              }
            }
          }
        })
        .catch(() => {
          commonServices.showError(this, 'msg.error.error');
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    async getDetails() {
      this.isLoadingDetails = true;
      await commentServices
        .getDetail(this.id)
        .then((result) => {
          if (result) {
            this.details = result.data.getArticleComment;
            if (result.data.getArticleComment.replyComments.items && result.data.getArticleComment.replyComments.items.length > 0){
              const childs = _.orderBy(result.data.getArticleComment.replyComments.items, 'createdAt', 'asc');
              this.details.replyComments.items = childs;
            }
          }
        })
        .catch(() => {})
        .finally(() => {
          this.isLoadingDetails = false;
        });
    },
    async getListArticleComments() {
      this.isLoading = true;
      await commentServices
        .getListArticleComments(this.id)
        .then((result) => {
          if (result) {
            this.details = result.data.getArticleComment;
          }
        })
        .catch(() => {})
        .finally(() => {
          this.isLoading = false;
        });
    },
    onSelectedDelete(item) {
      this.itemSelectedDelete = item;
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
    async handleSubmit() {
      await commentServices
        .deleteReply(this.itemSelectedDelete.id)
        .then((result) => {
          if (result) {
            if (result.errors) {
              commonServices.showError(this, 'msg.error.error');
            }
            this.getDetails();
            commonServices.showSuccess(this, 'msg.success.success');
            this.$nextTick(() => {
              this.$refs['my-modal'].toggle('#toggle-btn');
            });
          }
        })
        .catch((error) => {
          console.log(error);
          commonServices.showError(this, 'msg.error.error');
        })
        .finally(() => {
        });
    },
  },
};
</script>
